import { default as accountSU1DBtAPJXMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/account.vue?macro=true";
import { default as index5OMYf5z4VvMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/brevo/index.vue?macro=true";
import { default as order_45shipment_45createdAKQNBdsuSjMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/brevo/order-shipment-created.vue?macro=true";
import { default as order_45shipment_45deliveredZuet3nSZXBMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/brevo/order-shipment-delivered.vue?macro=true";
import { default as order_45successJ48SKb41RtMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/brevo/order-success.vue?macro=true";
import { default as _91id_93UZoVUeO6K2Meta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/cases/[id].vue?macro=true";
import { default as indexo9dRO3PZeuMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/cases/index.vue?macro=true";
import { default as configfNkE7VYkliMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/config.vue?macro=true";
import { default as respondbci77tWAeKMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/contact/respond.vue?macro=true";
import { default as indexWIhX8f7jqHMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/fulfillers/index.vue?macro=true";
import { default as indexoVV7Y0rcSWMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/index.vue?macro=true";
import { default as indexCcxVeSOhJWMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/jobs/index.vue?macro=true";
import { default as status_45overviewuvViVx24ZpMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/orders/status-overview.vue?macro=true";
import { default as _91status_93jZhNAOiESBMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/orders/status/[status].vue?macro=true";
import { default as indexSO6SS4msRCMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/orders/status/index.vue?macro=true";
import { default as indexTs2z5CaaluMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/partners/applicants/index.vue?macro=true";
import { default as indexFYxJxU9u6EMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/partners/index.vue?macro=true";
import { default as imagesHrVYc8fnibMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/printify/images.vue?macro=true";
import { default as indexyPTFpswJr7Meta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/printify/orders/index.vue?macro=true";
import { default as indexijJo6UGl05Meta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/printify/products/index.vue?macro=true";
import { default as manual_45publish_45failtNmISixLfNMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/printify/products/manual-publish-fail.vue?macro=true";
import { default as manual_45unpublishtlP36ylhISMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/printify/products/manual-unpublish.vue?macro=true";
import { default as indexjZfe03q6kiMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/products/index.vue?macro=true";
import { default as setRoleByUidUvY4doW3sQMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/users/setRoleByUid.vue?macro=true";
import { default as indexSKw8r44zFrMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/webhooks/printify/index.vue?macro=true";
import { default as indexJoMZtSc5HOMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/wishes/index.vue?macro=true";
import { default as ashUCqINAYTEPMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/ash.vue?macro=true";
import { default as checkout7x2U7t8Te8Meta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/checkout.vue?macro=true";
import { default as contact8pA43pCk1FMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/contact.vue?macro=true";
import { default as index4J1RR01cqgMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/index.vue?macro=true";
import { default as _91id_93Hcj88OgujIMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/jobs/[id].vue?macro=true";
import { default as generaluyn3LOiGn0Meta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/jobs/general.vue?macro=true";
import { default as indexlTy96b7vL1Meta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/jobs/index.vue?macro=true";
import { default as disclosureseNB2KY5jDwMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/legal/disclosures.vue?macro=true";
import { default as index3KEsLQ3AivMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/legal/index.vue?macro=true";
import { default as manufacturingTermscIWNmb8xZiMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/legal/manufacturingTerms.vue?macro=true";
import { default as partnerTermsVDVh92r2U6Meta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/legal/partnerTerms.vue?macro=true";
import { default as privacyPolicyfKFhMycbMDMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/legal/privacyPolicy.vue?macro=true";
import { default as termsAndConditionsb149BR5khPMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/legal/termsAndConditions.vue?macro=true";
import { default as login0nwzKEmyGeMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/login.vue?macro=true";
import { default as logoutGcCqX3DvkTMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/logout.vue?macro=true";
import { default as paymentaFOEOhTmj7Meta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/payment.vue?macro=true";
import { default as product_45requestlZh7ToeitLMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/product-request.vue?macro=true";
import { default as searchioQIEiRMAGMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/search.vue?macro=true";
import { default as faqssjarlsbdMFMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/faqs.vue?macro=true";
import { default as indexkVpCfAaLGNMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/index.vue?macro=true";
import { default as _91id_93nEnxH8iUbfMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/orders/[id].vue?macro=true";
import { default as indexectJvY5vp5Meta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/analytics/index.vue?macro=true";
import { default as connect_45stripen9W0p7AOzxMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/connect-stripe.vue?macro=true";
import { default as getting_45starteddGaLoNljbdMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/getting-started.vue?macro=true";
import { default as index6NoeeVLepJMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/index.vue?macro=true";
import { default as create4s309LiEA5Meta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/products/create.vue?macro=true";
import { default as indexQQfSt7qPFGMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/products/index.vue?macro=true";
import { default as profilePlD2reReGEMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/profile.vue?macro=true";
import { default as workerprofileMFnCt5htOcMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/workerprofile.vue?macro=true";
import { default as fanClub8RfaGCcguyMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fanClub.vue?macro=true";
import { default as fulfillment_45applicationunbL7vdvQZMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment-application.vue?macro=true";
import { default as indexkYRACeY3qlMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/analytics/index.vue?macro=true";
import { default as carriersMIk9T32HEKMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/carriers.vue?macro=true";
import { default as indexHaESlE5Bo7Meta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/cases/index.vue?macro=true";
import { default as index2xRxhIRs2RMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/config/index.vue?macro=true";
import { default as getting_45startedl4tDbBzGkYMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/getting-started.vue?macro=true";
import { default as indexPhKaCK3YCHMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/index.vue?macro=true";
import { default as indexmfRgKXy687Meta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/orders/index.vue?macro=true";
import { default as indexQhB5zd7evZMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/warehouses/[warehouseName]/batches/index.vue?macro=true";
import { default as indexRA8AXXhZAqMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/warehouses/[warehouseName]/cases/index.vue?macro=true";
import { default as indexBi6jcLfzmRMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/warehouses/[warehouseName]/manifests/index.vue?macro=true";
import { default as indexzL6AS5bD9kMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/warehouses/[warehouseName]/parcels/index.vue?macro=true";
import { default as indexmpQloftp4hMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/warehouses/[warehouseName]/shipments/index.vue?macro=true";
import { default as createk9bGApwtt5Meta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/warehouses/create.vue?macro=true";
import { default as index3XMJQru4ShMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/warehouses/index.vue?macro=true";
import { default as indexbCjeVLUUOdMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/index.vue?macro=true";
import { default as indexlEyzndwHQYMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/warehouses/[id].vue/index.vue?macro=true";
import { default as register9JMVqFy2CLMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/register.vue?macro=true";
import { default as termsSVAlTY4UMrMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/terms.vue?macro=true";
import { default as indexw7EkhwRvOKMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/index.vue?macro=true";
import { default as indexvI7G8bk2V1Meta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/partners/index.vue?macro=true";
import { default as _91category_935mOXIkf8dcMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/products/[category].vue?macro=true";
import { default as indexPQSvXPIBelMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/products/index.vue?macro=true";
import { default as registerLxz0D2ceYfMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/register.vue?macro=true";
import { default as termszI5dVvIileMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/terms.vue?macro=true";
import { default as _91id_93kZ2b6HRw8gMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/product/[id].vue?macro=true";
import { default as _91category_936NlvuY2VysMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/products/[category].vue?macro=true";
import { default as indexGlokrGqUZDMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/shoppers/index.vue?macro=true";
import { default as indexe4iNe4spXJMeta } from "C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/stores/@[name]/index.vue?macro=true";
export default [
  {
    name: accountSU1DBtAPJXMeta?.name ?? "account",
    path: accountSU1DBtAPJXMeta?.path ?? "/account",
    meta: accountSU1DBtAPJXMeta || {},
    alias: accountSU1DBtAPJXMeta?.alias || [],
    redirect: accountSU1DBtAPJXMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/account.vue").then(m => m.default || m)
  },
  {
    name: index5OMYf5z4VvMeta?.name ?? "admin-brevo",
    path: index5OMYf5z4VvMeta?.path ?? "/admin/brevo",
    meta: index5OMYf5z4VvMeta || {},
    alias: index5OMYf5z4VvMeta?.alias || [],
    redirect: index5OMYf5z4VvMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/brevo/index.vue").then(m => m.default || m)
  },
  {
    name: order_45shipment_45createdAKQNBdsuSjMeta?.name ?? "admin-brevo-order-shipment-created",
    path: order_45shipment_45createdAKQNBdsuSjMeta?.path ?? "/admin/brevo/order-shipment-created",
    meta: order_45shipment_45createdAKQNBdsuSjMeta || {},
    alias: order_45shipment_45createdAKQNBdsuSjMeta?.alias || [],
    redirect: order_45shipment_45createdAKQNBdsuSjMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/brevo/order-shipment-created.vue").then(m => m.default || m)
  },
  {
    name: order_45shipment_45deliveredZuet3nSZXBMeta?.name ?? "admin-brevo-order-shipment-delivered",
    path: order_45shipment_45deliveredZuet3nSZXBMeta?.path ?? "/admin/brevo/order-shipment-delivered",
    meta: order_45shipment_45deliveredZuet3nSZXBMeta || {},
    alias: order_45shipment_45deliveredZuet3nSZXBMeta?.alias || [],
    redirect: order_45shipment_45deliveredZuet3nSZXBMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/brevo/order-shipment-delivered.vue").then(m => m.default || m)
  },
  {
    name: order_45successJ48SKb41RtMeta?.name ?? "admin-brevo-order-success",
    path: order_45successJ48SKb41RtMeta?.path ?? "/admin/brevo/order-success",
    meta: order_45successJ48SKb41RtMeta || {},
    alias: order_45successJ48SKb41RtMeta?.alias || [],
    redirect: order_45successJ48SKb41RtMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/brevo/order-success.vue").then(m => m.default || m)
  },
  {
    name: _91id_93UZoVUeO6K2Meta?.name ?? "admin-cases-id",
    path: _91id_93UZoVUeO6K2Meta?.path ?? "/admin/cases/:id()",
    meta: _91id_93UZoVUeO6K2Meta || {},
    alias: _91id_93UZoVUeO6K2Meta?.alias || [],
    redirect: _91id_93UZoVUeO6K2Meta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/cases/[id].vue").then(m => m.default || m)
  },
  {
    name: indexo9dRO3PZeuMeta?.name ?? "admin-cases",
    path: indexo9dRO3PZeuMeta?.path ?? "/admin/cases",
    meta: indexo9dRO3PZeuMeta || {},
    alias: indexo9dRO3PZeuMeta?.alias || [],
    redirect: indexo9dRO3PZeuMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/cases/index.vue").then(m => m.default || m)
  },
  {
    name: configfNkE7VYkliMeta?.name ?? "admin-config",
    path: configfNkE7VYkliMeta?.path ?? "/admin/config",
    meta: configfNkE7VYkliMeta || {},
    alias: configfNkE7VYkliMeta?.alias || [],
    redirect: configfNkE7VYkliMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/config.vue").then(m => m.default || m)
  },
  {
    name: respondbci77tWAeKMeta?.name ?? "admin-contact-respond",
    path: respondbci77tWAeKMeta?.path ?? "/admin/contact/respond",
    meta: respondbci77tWAeKMeta || {},
    alias: respondbci77tWAeKMeta?.alias || [],
    redirect: respondbci77tWAeKMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/contact/respond.vue").then(m => m.default || m)
  },
  {
    name: indexWIhX8f7jqHMeta?.name ?? "admin-fulfillers",
    path: indexWIhX8f7jqHMeta?.path ?? "/admin/fulfillers",
    meta: indexWIhX8f7jqHMeta || {},
    alias: indexWIhX8f7jqHMeta?.alias || [],
    redirect: indexWIhX8f7jqHMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/fulfillers/index.vue").then(m => m.default || m)
  },
  {
    name: indexoVV7Y0rcSWMeta?.name ?? "admin",
    path: indexoVV7Y0rcSWMeta?.path ?? "/admin",
    meta: indexoVV7Y0rcSWMeta || {},
    alias: indexoVV7Y0rcSWMeta?.alias || [],
    redirect: indexoVV7Y0rcSWMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexCcxVeSOhJWMeta?.name ?? "admin-jobs",
    path: indexCcxVeSOhJWMeta?.path ?? "/admin/jobs",
    meta: indexCcxVeSOhJWMeta || {},
    alias: indexCcxVeSOhJWMeta?.alias || [],
    redirect: indexCcxVeSOhJWMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: status_45overviewuvViVx24ZpMeta?.name ?? "admin-orders-status-overview",
    path: status_45overviewuvViVx24ZpMeta?.path ?? "/admin/orders/status-overview",
    meta: status_45overviewuvViVx24ZpMeta || {},
    alias: status_45overviewuvViVx24ZpMeta?.alias || [],
    redirect: status_45overviewuvViVx24ZpMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/orders/status-overview.vue").then(m => m.default || m)
  },
  {
    name: _91status_93jZhNAOiESBMeta?.name ?? "admin-orders-status-status",
    path: _91status_93jZhNAOiESBMeta?.path ?? "/admin/orders/status/:status()",
    meta: _91status_93jZhNAOiESBMeta || {},
    alias: _91status_93jZhNAOiESBMeta?.alias || [],
    redirect: _91status_93jZhNAOiESBMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/orders/status/[status].vue").then(m => m.default || m)
  },
  {
    name: indexSO6SS4msRCMeta?.name ?? "admin-orders-status",
    path: indexSO6SS4msRCMeta?.path ?? "/admin/orders/status",
    meta: indexSO6SS4msRCMeta || {},
    alias: indexSO6SS4msRCMeta?.alias || [],
    redirect: indexSO6SS4msRCMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/orders/status/index.vue").then(m => m.default || m)
  },
  {
    name: indexTs2z5CaaluMeta?.name ?? "admin-partners-applicants",
    path: indexTs2z5CaaluMeta?.path ?? "/admin/partners/applicants",
    meta: indexTs2z5CaaluMeta || {},
    alias: indexTs2z5CaaluMeta?.alias || [],
    redirect: indexTs2z5CaaluMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/partners/applicants/index.vue").then(m => m.default || m)
  },
  {
    name: indexFYxJxU9u6EMeta?.name ?? "admin-partners",
    path: indexFYxJxU9u6EMeta?.path ?? "/admin/partners",
    meta: indexFYxJxU9u6EMeta || {},
    alias: indexFYxJxU9u6EMeta?.alias || [],
    redirect: indexFYxJxU9u6EMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/partners/index.vue").then(m => m.default || m)
  },
  {
    name: imagesHrVYc8fnibMeta?.name ?? "admin-printify-images",
    path: imagesHrVYc8fnibMeta?.path ?? "/admin/printify/images",
    meta: imagesHrVYc8fnibMeta || {},
    alias: imagesHrVYc8fnibMeta?.alias || [],
    redirect: imagesHrVYc8fnibMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/printify/images.vue").then(m => m.default || m)
  },
  {
    name: indexyPTFpswJr7Meta?.name ?? "admin-printify-orders",
    path: indexyPTFpswJr7Meta?.path ?? "/admin/printify/orders",
    meta: indexyPTFpswJr7Meta || {},
    alias: indexyPTFpswJr7Meta?.alias || [],
    redirect: indexyPTFpswJr7Meta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/printify/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexijJo6UGl05Meta?.name ?? "admin-printify-products",
    path: indexijJo6UGl05Meta?.path ?? "/admin/printify/products",
    meta: indexijJo6UGl05Meta || {},
    alias: indexijJo6UGl05Meta?.alias || [],
    redirect: indexijJo6UGl05Meta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/printify/products/index.vue").then(m => m.default || m)
  },
  {
    name: manual_45publish_45failtNmISixLfNMeta?.name ?? "admin-printify-products-manual-publish-fail",
    path: manual_45publish_45failtNmISixLfNMeta?.path ?? "/admin/printify/products/manual-publish-fail",
    meta: manual_45publish_45failtNmISixLfNMeta || {},
    alias: manual_45publish_45failtNmISixLfNMeta?.alias || [],
    redirect: manual_45publish_45failtNmISixLfNMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/printify/products/manual-publish-fail.vue").then(m => m.default || m)
  },
  {
    name: manual_45unpublishtlP36ylhISMeta?.name ?? "admin-printify-products-manual-unpublish",
    path: manual_45unpublishtlP36ylhISMeta?.path ?? "/admin/printify/products/manual-unpublish",
    meta: manual_45unpublishtlP36ylhISMeta || {},
    alias: manual_45unpublishtlP36ylhISMeta?.alias || [],
    redirect: manual_45unpublishtlP36ylhISMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/printify/products/manual-unpublish.vue").then(m => m.default || m)
  },
  {
    name: indexjZfe03q6kiMeta?.name ?? "admin-products",
    path: indexjZfe03q6kiMeta?.path ?? "/admin/products",
    meta: indexjZfe03q6kiMeta || {},
    alias: indexjZfe03q6kiMeta?.alias || [],
    redirect: indexjZfe03q6kiMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/products/index.vue").then(m => m.default || m)
  },
  {
    name: setRoleByUidUvY4doW3sQMeta?.name ?? "admin-users-setRoleByUid",
    path: setRoleByUidUvY4doW3sQMeta?.path ?? "/admin/users/setRoleByUid",
    meta: setRoleByUidUvY4doW3sQMeta || {},
    alias: setRoleByUidUvY4doW3sQMeta?.alias || [],
    redirect: setRoleByUidUvY4doW3sQMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/users/setRoleByUid.vue").then(m => m.default || m)
  },
  {
    name: indexSKw8r44zFrMeta?.name ?? "admin-webhooks-printify",
    path: indexSKw8r44zFrMeta?.path ?? "/admin/webhooks/printify",
    meta: indexSKw8r44zFrMeta || {},
    alias: indexSKw8r44zFrMeta?.alias || [],
    redirect: indexSKw8r44zFrMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/webhooks/printify/index.vue").then(m => m.default || m)
  },
  {
    name: indexJoMZtSc5HOMeta?.name ?? "admin-wishes",
    path: indexJoMZtSc5HOMeta?.path ?? "/admin/wishes",
    meta: indexJoMZtSc5HOMeta || {},
    alias: indexJoMZtSc5HOMeta?.alias || [],
    redirect: indexJoMZtSc5HOMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/admin/wishes/index.vue").then(m => m.default || m)
  },
  {
    name: ashUCqINAYTEPMeta?.name ?? "ash",
    path: ashUCqINAYTEPMeta?.path ?? "/ash",
    meta: ashUCqINAYTEPMeta || {},
    alias: ashUCqINAYTEPMeta?.alias || [],
    redirect: ashUCqINAYTEPMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/ash.vue").then(m => m.default || m)
  },
  {
    name: checkout7x2U7t8Te8Meta?.name ?? "checkout",
    path: checkout7x2U7t8Te8Meta?.path ?? "/checkout",
    meta: checkout7x2U7t8Te8Meta || {},
    alias: checkout7x2U7t8Te8Meta?.alias || [],
    redirect: checkout7x2U7t8Te8Meta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: contact8pA43pCk1FMeta?.name ?? "contact",
    path: contact8pA43pCk1FMeta?.path ?? "/contact",
    meta: contact8pA43pCk1FMeta || {},
    alias: contact8pA43pCk1FMeta?.alias || [],
    redirect: contact8pA43pCk1FMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: index4J1RR01cqgMeta?.name ?? "index",
    path: index4J1RR01cqgMeta?.path ?? "/",
    meta: index4J1RR01cqgMeta || {},
    alias: index4J1RR01cqgMeta?.alias || [],
    redirect: index4J1RR01cqgMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Hcj88OgujIMeta?.name ?? "jobs-id",
    path: _91id_93Hcj88OgujIMeta?.path ?? "/jobs/:id()",
    meta: _91id_93Hcj88OgujIMeta || {},
    alias: _91id_93Hcj88OgujIMeta?.alias || [],
    redirect: _91id_93Hcj88OgujIMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: generaluyn3LOiGn0Meta?.name ?? "jobs-general",
    path: generaluyn3LOiGn0Meta?.path ?? "/jobs/general",
    meta: generaluyn3LOiGn0Meta || {},
    alias: generaluyn3LOiGn0Meta?.alias || [],
    redirect: generaluyn3LOiGn0Meta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/jobs/general.vue").then(m => m.default || m)
  },
  {
    name: indexlTy96b7vL1Meta?.name ?? "jobs",
    path: indexlTy96b7vL1Meta?.path ?? "/jobs",
    meta: indexlTy96b7vL1Meta || {},
    alias: indexlTy96b7vL1Meta?.alias || [],
    redirect: indexlTy96b7vL1Meta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: disclosureseNB2KY5jDwMeta?.name ?? "legal-disclosures",
    path: disclosureseNB2KY5jDwMeta?.path ?? "/legal/disclosures",
    meta: disclosureseNB2KY5jDwMeta || {},
    alias: disclosureseNB2KY5jDwMeta?.alias || [],
    redirect: disclosureseNB2KY5jDwMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/legal/disclosures.vue").then(m => m.default || m)
  },
  {
    name: index3KEsLQ3AivMeta?.name ?? "legal",
    path: index3KEsLQ3AivMeta?.path ?? "/legal",
    meta: index3KEsLQ3AivMeta || {},
    alias: index3KEsLQ3AivMeta?.alias || [],
    redirect: index3KEsLQ3AivMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: manufacturingTermscIWNmb8xZiMeta?.name ?? "legal-manufacturingTerms",
    path: manufacturingTermscIWNmb8xZiMeta?.path ?? "/legal/manufacturingTerms",
    meta: manufacturingTermscIWNmb8xZiMeta || {},
    alias: manufacturingTermscIWNmb8xZiMeta?.alias || [],
    redirect: manufacturingTermscIWNmb8xZiMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/legal/manufacturingTerms.vue").then(m => m.default || m)
  },
  {
    name: partnerTermsVDVh92r2U6Meta?.name ?? "legal-partnerTerms",
    path: partnerTermsVDVh92r2U6Meta?.path ?? "/legal/partnerTerms",
    meta: partnerTermsVDVh92r2U6Meta || {},
    alias: partnerTermsVDVh92r2U6Meta?.alias || [],
    redirect: partnerTermsVDVh92r2U6Meta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/legal/partnerTerms.vue").then(m => m.default || m)
  },
  {
    name: privacyPolicyfKFhMycbMDMeta?.name ?? "legal-privacyPolicy",
    path: privacyPolicyfKFhMycbMDMeta?.path ?? "/legal/privacyPolicy",
    meta: privacyPolicyfKFhMycbMDMeta || {},
    alias: privacyPolicyfKFhMycbMDMeta?.alias || [],
    redirect: privacyPolicyfKFhMycbMDMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/legal/privacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: termsAndConditionsb149BR5khPMeta?.name ?? "legal-termsAndConditions",
    path: termsAndConditionsb149BR5khPMeta?.path ?? "/legal/termsAndConditions",
    meta: termsAndConditionsb149BR5khPMeta || {},
    alias: termsAndConditionsb149BR5khPMeta?.alias || [],
    redirect: termsAndConditionsb149BR5khPMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/legal/termsAndConditions.vue").then(m => m.default || m)
  },
  {
    name: login0nwzKEmyGeMeta?.name ?? "login",
    path: login0nwzKEmyGeMeta?.path ?? "/login",
    meta: login0nwzKEmyGeMeta || {},
    alias: login0nwzKEmyGeMeta?.alias || [],
    redirect: login0nwzKEmyGeMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutGcCqX3DvkTMeta?.name ?? "logout",
    path: logoutGcCqX3DvkTMeta?.path ?? "/logout",
    meta: logoutGcCqX3DvkTMeta || {},
    alias: logoutGcCqX3DvkTMeta?.alias || [],
    redirect: logoutGcCqX3DvkTMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: paymentaFOEOhTmj7Meta?.name ?? "payment",
    path: paymentaFOEOhTmj7Meta?.path ?? "/payment",
    meta: paymentaFOEOhTmj7Meta || {},
    alias: paymentaFOEOhTmj7Meta?.alias || [],
    redirect: paymentaFOEOhTmj7Meta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/payment.vue").then(m => m.default || m)
  },
  {
    name: product_45requestlZh7ToeitLMeta?.name ?? "product-request",
    path: product_45requestlZh7ToeitLMeta?.path ?? "/product-request",
    meta: product_45requestlZh7ToeitLMeta || {},
    alias: product_45requestlZh7ToeitLMeta?.alias || [],
    redirect: product_45requestlZh7ToeitLMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/product-request.vue").then(m => m.default || m)
  },
  {
    name: searchioQIEiRMAGMeta?.name ?? "search",
    path: searchioQIEiRMAGMeta?.path ?? "/search",
    meta: searchioQIEiRMAGMeta || {},
    alias: searchioQIEiRMAGMeta?.alias || [],
    redirect: searchioQIEiRMAGMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/search.vue").then(m => m.default || m)
  },
  {
    name: faqssjarlsbdMFMeta?.name ?? "shop-faqs",
    path: faqssjarlsbdMFMeta?.path ?? "/shop/faqs",
    meta: faqssjarlsbdMFMeta || {},
    alias: faqssjarlsbdMFMeta?.alias || [],
    redirect: faqssjarlsbdMFMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/faqs.vue").then(m => m.default || m)
  },
  {
    name: indexkVpCfAaLGNMeta?.name ?? "shop",
    path: indexkVpCfAaLGNMeta?.path ?? "/shop",
    meta: indexkVpCfAaLGNMeta || {},
    alias: indexkVpCfAaLGNMeta?.alias || [],
    redirect: indexkVpCfAaLGNMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nEnxH8iUbfMeta?.name ?? "shop-orders-id",
    path: _91id_93nEnxH8iUbfMeta?.path ?? "/shop/orders/:id()",
    meta: _91id_93nEnxH8iUbfMeta || {},
    alias: _91id_93nEnxH8iUbfMeta?.alias || [],
    redirect: _91id_93nEnxH8iUbfMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexectJvY5vp5Meta?.name ?? "shop-partners-@name-administration-analytics",
    path: indexectJvY5vp5Meta?.path ?? "/shop/partners/@:name()/administration/analytics",
    meta: indexectJvY5vp5Meta || {},
    alias: indexectJvY5vp5Meta?.alias || [],
    redirect: indexectJvY5vp5Meta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: connect_45stripen9W0p7AOzxMeta?.name ?? "shop-partners-@name-administration-connect-stripe",
    path: connect_45stripen9W0p7AOzxMeta?.path ?? "/shop/partners/@:name()/administration/connect-stripe",
    meta: connect_45stripen9W0p7AOzxMeta || {},
    alias: connect_45stripen9W0p7AOzxMeta?.alias || [],
    redirect: connect_45stripen9W0p7AOzxMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/connect-stripe.vue").then(m => m.default || m)
  },
  {
    name: getting_45starteddGaLoNljbdMeta?.name ?? "shop-partners-@name-administration-getting-started",
    path: getting_45starteddGaLoNljbdMeta?.path ?? "/shop/partners/@:name()/administration/getting-started",
    meta: getting_45starteddGaLoNljbdMeta || {},
    alias: getting_45starteddGaLoNljbdMeta?.alias || [],
    redirect: getting_45starteddGaLoNljbdMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/getting-started.vue").then(m => m.default || m)
  },
  {
    name: index6NoeeVLepJMeta?.name ?? "shop-partners-@name-administration",
    path: index6NoeeVLepJMeta?.path ?? "/shop/partners/@:name()/administration",
    meta: index6NoeeVLepJMeta || {},
    alias: index6NoeeVLepJMeta?.alias || [],
    redirect: index6NoeeVLepJMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/index.vue").then(m => m.default || m)
  },
  {
    name: create4s309LiEA5Meta?.name ?? "shop-partners-@name-administration-products-create",
    path: create4s309LiEA5Meta?.path ?? "/shop/partners/@:name()/administration/products/create",
    meta: create4s309LiEA5Meta || {},
    alias: create4s309LiEA5Meta?.alias || [],
    redirect: create4s309LiEA5Meta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/products/create.vue").then(m => m.default || m)
  },
  {
    name: indexQQfSt7qPFGMeta?.name ?? "shop-partners-@name-administration-products",
    path: indexQQfSt7qPFGMeta?.path ?? "/shop/partners/@:name()/administration/products",
    meta: indexQQfSt7qPFGMeta || {},
    alias: indexQQfSt7qPFGMeta?.alias || [],
    redirect: indexQQfSt7qPFGMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/products/index.vue").then(m => m.default || m)
  },
  {
    name: profilePlD2reReGEMeta?.name ?? "shop-partners-@name-administration-profile",
    path: profilePlD2reReGEMeta?.path ?? "/shop/partners/@:name()/administration/profile",
    meta: profilePlD2reReGEMeta || {},
    alias: profilePlD2reReGEMeta?.alias || [],
    redirect: profilePlD2reReGEMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/profile.vue").then(m => m.default || m)
  },
  {
    name: workerprofileMFnCt5htOcMeta?.name ?? "shop-partners-@name-administration-workerprofile",
    path: workerprofileMFnCt5htOcMeta?.path ?? "/shop/partners/@:name()/administration/workerprofile",
    meta: workerprofileMFnCt5htOcMeta || {},
    alias: workerprofileMFnCt5htOcMeta?.alias || [],
    redirect: workerprofileMFnCt5htOcMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/administration/workerprofile.vue").then(m => m.default || m)
  },
  {
    name: fanClub8RfaGCcguyMeta?.name ?? "shop-partners-@name-fanClub",
    path: fanClub8RfaGCcguyMeta?.path ?? "/shop/partners/@:name()/fanClub",
    meta: fanClub8RfaGCcguyMeta || {},
    alias: fanClub8RfaGCcguyMeta?.alias || [],
    redirect: fanClub8RfaGCcguyMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fanClub.vue").then(m => m.default || m)
  },
  {
    name: fulfillment_45applicationunbL7vdvQZMeta?.name ?? "shop-partners-@name-fulfillment-application",
    path: fulfillment_45applicationunbL7vdvQZMeta?.path ?? "/shop/partners/@:name()/fulfillment-application",
    meta: fulfillment_45applicationunbL7vdvQZMeta || {},
    alias: fulfillment_45applicationunbL7vdvQZMeta?.alias || [],
    redirect: fulfillment_45applicationunbL7vdvQZMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment-application.vue").then(m => m.default || m)
  },
  {
    name: indexkYRACeY3qlMeta?.name ?? "shop-partners-@name-fulfillment-@name-administration-analytics",
    path: indexkYRACeY3qlMeta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/administration/analytics",
    meta: indexkYRACeY3qlMeta || {},
    alias: indexkYRACeY3qlMeta?.alias || [],
    redirect: indexkYRACeY3qlMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: carriersMIk9T32HEKMeta?.name ?? "shop-partners-@name-fulfillment-@name-administration-carriers",
    path: carriersMIk9T32HEKMeta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/administration/carriers",
    meta: carriersMIk9T32HEKMeta || {},
    alias: carriersMIk9T32HEKMeta?.alias || [],
    redirect: carriersMIk9T32HEKMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/carriers.vue").then(m => m.default || m)
  },
  {
    name: indexHaESlE5Bo7Meta?.name ?? "shop-partners-@name-fulfillment-@name-administration-cases",
    path: indexHaESlE5Bo7Meta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/administration/cases",
    meta: indexHaESlE5Bo7Meta || {},
    alias: indexHaESlE5Bo7Meta?.alias || [],
    redirect: indexHaESlE5Bo7Meta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/cases/index.vue").then(m => m.default || m)
  },
  {
    name: index2xRxhIRs2RMeta?.name ?? "shop-partners-@name-fulfillment-@name-administration-config",
    path: index2xRxhIRs2RMeta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/administration/config",
    meta: index2xRxhIRs2RMeta || {},
    alias: index2xRxhIRs2RMeta?.alias || [],
    redirect: index2xRxhIRs2RMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/config/index.vue").then(m => m.default || m)
  },
  {
    name: getting_45startedl4tDbBzGkYMeta?.name ?? "shop-partners-@name-fulfillment-@name-administration-getting-started",
    path: getting_45startedl4tDbBzGkYMeta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/administration/getting-started",
    meta: getting_45startedl4tDbBzGkYMeta || {},
    alias: getting_45startedl4tDbBzGkYMeta?.alias || [],
    redirect: getting_45startedl4tDbBzGkYMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/getting-started.vue").then(m => m.default || m)
  },
  {
    name: indexPhKaCK3YCHMeta?.name ?? "shop-partners-@name-fulfillment-@name-administration",
    path: indexPhKaCK3YCHMeta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/administration",
    meta: indexPhKaCK3YCHMeta || {},
    alias: indexPhKaCK3YCHMeta?.alias || [],
    redirect: indexPhKaCK3YCHMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/index.vue").then(m => m.default || m)
  },
  {
    name: indexmfRgKXy687Meta?.name ?? "shop-partners-@name-fulfillment-@name-administration-orders",
    path: indexmfRgKXy687Meta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/administration/orders",
    meta: indexmfRgKXy687Meta || {},
    alias: indexmfRgKXy687Meta?.alias || [],
    redirect: indexmfRgKXy687Meta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexQhB5zd7evZMeta?.name ?? "shop-partners-@name-fulfillment-@name-administration-warehouses-warehouseName-batches",
    path: indexQhB5zd7evZMeta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/administration/warehouses/:warehouseName()/batches",
    meta: indexQhB5zd7evZMeta || {},
    alias: indexQhB5zd7evZMeta?.alias || [],
    redirect: indexQhB5zd7evZMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/warehouses/[warehouseName]/batches/index.vue").then(m => m.default || m)
  },
  {
    name: indexRA8AXXhZAqMeta?.name ?? "shop-partners-@name-fulfillment-@name-administration-warehouses-warehouseName-cases",
    path: indexRA8AXXhZAqMeta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/administration/warehouses/:warehouseName()/cases",
    meta: indexRA8AXXhZAqMeta || {},
    alias: indexRA8AXXhZAqMeta?.alias || [],
    redirect: indexRA8AXXhZAqMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/warehouses/[warehouseName]/cases/index.vue").then(m => m.default || m)
  },
  {
    name: indexBi6jcLfzmRMeta?.name ?? "shop-partners-@name-fulfillment-@name-administration-warehouses-warehouseName-manifests",
    path: indexBi6jcLfzmRMeta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/administration/warehouses/:warehouseName()/manifests",
    meta: indexBi6jcLfzmRMeta || {},
    alias: indexBi6jcLfzmRMeta?.alias || [],
    redirect: indexBi6jcLfzmRMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/warehouses/[warehouseName]/manifests/index.vue").then(m => m.default || m)
  },
  {
    name: indexzL6AS5bD9kMeta?.name ?? "shop-partners-@name-fulfillment-@name-administration-warehouses-warehouseName-parcels",
    path: indexzL6AS5bD9kMeta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/administration/warehouses/:warehouseName()/parcels",
    meta: indexzL6AS5bD9kMeta || {},
    alias: indexzL6AS5bD9kMeta?.alias || [],
    redirect: indexzL6AS5bD9kMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/warehouses/[warehouseName]/parcels/index.vue").then(m => m.default || m)
  },
  {
    name: indexmpQloftp4hMeta?.name ?? "shop-partners-@name-fulfillment-@name-administration-warehouses-warehouseName-shipments",
    path: indexmpQloftp4hMeta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/administration/warehouses/:warehouseName()/shipments",
    meta: indexmpQloftp4hMeta || {},
    alias: indexmpQloftp4hMeta?.alias || [],
    redirect: indexmpQloftp4hMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/warehouses/[warehouseName]/shipments/index.vue").then(m => m.default || m)
  },
  {
    name: createk9bGApwtt5Meta?.name ?? "shop-partners-@name-fulfillment-@name-administration-warehouses-create",
    path: createk9bGApwtt5Meta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/administration/warehouses/create",
    meta: createk9bGApwtt5Meta || {},
    alias: createk9bGApwtt5Meta?.alias || [],
    redirect: createk9bGApwtt5Meta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/warehouses/create.vue").then(m => m.default || m)
  },
  {
    name: index3XMJQru4ShMeta?.name ?? "shop-partners-@name-fulfillment-@name-administration-warehouses",
    path: index3XMJQru4ShMeta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/administration/warehouses",
    meta: index3XMJQru4ShMeta || {},
    alias: index3XMJQru4ShMeta?.alias || [],
    redirect: index3XMJQru4ShMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/administration/warehouses/index.vue").then(m => m.default || m)
  },
  {
    name: indexbCjeVLUUOdMeta?.name ?? "shop-partners-@name-fulfillment-@name",
    path: indexbCjeVLUUOdMeta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()",
    meta: indexbCjeVLUUOdMeta || {},
    alias: indexbCjeVLUUOdMeta?.alias || [],
    redirect: indexbCjeVLUUOdMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlEyzndwHQYMeta?.name ?? "shop-partners-@name-fulfillment-@name-warehouses-id.vue",
    path: indexlEyzndwHQYMeta?.path ?? "/shop/partners/@:name()/fulfillment/@:name()/warehouses/:id().vue",
    meta: indexlEyzndwHQYMeta || {},
    alias: indexlEyzndwHQYMeta?.alias || [],
    redirect: indexlEyzndwHQYMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/@[name]/warehouses/[id].vue/index.vue").then(m => m.default || m)
  },
  {
    name: register9JMVqFy2CLMeta?.name ?? "shop-partners-@name-fulfillment-register",
    path: register9JMVqFy2CLMeta?.path ?? "/shop/partners/@:name()/fulfillment/register",
    meta: register9JMVqFy2CLMeta || {},
    alias: register9JMVqFy2CLMeta?.alias || [],
    redirect: register9JMVqFy2CLMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/register.vue").then(m => m.default || m)
  },
  {
    name: termsSVAlTY4UMrMeta?.name ?? "shop-partners-@name-fulfillment-terms",
    path: termsSVAlTY4UMrMeta?.path ?? "/shop/partners/@:name()/fulfillment/terms",
    meta: termsSVAlTY4UMrMeta || {},
    alias: termsSVAlTY4UMrMeta?.alias || [],
    redirect: termsSVAlTY4UMrMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/fulfillment/terms.vue").then(m => m.default || m)
  },
  {
    name: indexw7EkhwRvOKMeta?.name ?? "shop-partners-@name",
    path: indexw7EkhwRvOKMeta?.path ?? "/shop/partners/@:name()",
    meta: indexw7EkhwRvOKMeta || {},
    alias: indexw7EkhwRvOKMeta?.alias || [],
    redirect: indexw7EkhwRvOKMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvI7G8bk2V1Meta?.name ?? "shop-partners-@name-partners",
    path: indexvI7G8bk2V1Meta?.path ?? "/shop/partners/@:name()/partners",
    meta: indexvI7G8bk2V1Meta || {},
    alias: indexvI7G8bk2V1Meta?.alias || [],
    redirect: indexvI7G8bk2V1Meta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/partners/index.vue").then(m => m.default || m)
  },
  {
    name: _91category_935mOXIkf8dcMeta?.name ?? "shop-partners-@name-products-category",
    path: _91category_935mOXIkf8dcMeta?.path ?? "/shop/partners/@:name()/products/:category()",
    meta: _91category_935mOXIkf8dcMeta || {},
    alias: _91category_935mOXIkf8dcMeta?.alias || [],
    redirect: _91category_935mOXIkf8dcMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/products/[category].vue").then(m => m.default || m)
  },
  {
    name: indexPQSvXPIBelMeta?.name ?? "shop-partners-@name-products",
    path: indexPQSvXPIBelMeta?.path ?? "/shop/partners/@:name()/products",
    meta: indexPQSvXPIBelMeta || {},
    alias: indexPQSvXPIBelMeta?.alias || [],
    redirect: indexPQSvXPIBelMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/@[name]/products/index.vue").then(m => m.default || m)
  },
  {
    name: registerLxz0D2ceYfMeta?.name ?? "shop-partners-register",
    path: registerLxz0D2ceYfMeta?.path ?? "/shop/partners/register",
    meta: registerLxz0D2ceYfMeta || {},
    alias: registerLxz0D2ceYfMeta?.alias || [],
    redirect: registerLxz0D2ceYfMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/register.vue").then(m => m.default || m)
  },
  {
    name: termszI5dVvIileMeta?.name ?? "shop-partners-terms",
    path: termszI5dVvIileMeta?.path ?? "/shop/partners/terms",
    meta: termszI5dVvIileMeta || {},
    alias: termszI5dVvIileMeta?.alias || [],
    redirect: termszI5dVvIileMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/partners/terms.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kZ2b6HRw8gMeta?.name ?? "shop-product-id",
    path: _91id_93kZ2b6HRw8gMeta?.path ?? "/shop/product/:id()",
    meta: _91id_93kZ2b6HRw8gMeta || {},
    alias: _91id_93kZ2b6HRw8gMeta?.alias || [],
    redirect: _91id_93kZ2b6HRw8gMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/product/[id].vue").then(m => m.default || m)
  },
  {
    name: _91category_936NlvuY2VysMeta?.name ?? "shop-products-category",
    path: _91category_936NlvuY2VysMeta?.path ?? "/shop/products/:category()",
    meta: _91category_936NlvuY2VysMeta || {},
    alias: _91category_936NlvuY2VysMeta?.alias || [],
    redirect: _91category_936NlvuY2VysMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/products/[category].vue").then(m => m.default || m)
  },
  {
    name: indexGlokrGqUZDMeta?.name ?? "shop-shoppers",
    path: indexGlokrGqUZDMeta?.path ?? "/shop/shoppers",
    meta: indexGlokrGqUZDMeta || {},
    alias: indexGlokrGqUZDMeta?.alias || [],
    redirect: indexGlokrGqUZDMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/shoppers/index.vue").then(m => m.default || m)
  },
  {
    name: indexe4iNe4spXJMeta?.name ?? "shop-stores-@name",
    path: indexe4iNe4spXJMeta?.path ?? "/shop/stores/@:name()",
    meta: indexe4iNe4spXJMeta || {},
    alias: indexe4iNe4spXJMeta?.alias || [],
    redirect: indexe4iNe4spXJMeta?.redirect,
    component: () => import("C:/Users/ashle/OneDrive/Desktop/nyx-knacks/pages/shop/stores/@[name]/index.vue").then(m => m.default || m)
  }
]